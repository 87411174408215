<template>
  <div class="contents">
    <div class="title flexB">
      <h2>데이터 아카이빙 시스템 (Data Archiving System)</h2>
      <p>{{ name }} ({{ team }})</p>
    </div>
    <div class="box one filter">
      <div class="flex searching">
        <select v-model="inputMode">
          <option selected value="">전체</option>
          <option value="seriesTitle">시리즈제목</option>
          <option value="shortSynopsis">단문줄거리</option>
          <option value="episodeTitle">에피소드명</option>
          <option value="seriesSysnopsis">시리즈시놉시스</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
        <div class="buttons">
          <button @click="search" class="search">
            <span class="material-icons-outlined"> search </span>
          </button>
          <button @click="reset" class="reset">
            <span class="material-icons-outlined"> refresh </span>
          </button>
        </div>
      </div>
      <div class="filter-table">
        <el-descriptions :column="1" border :label-style="{ minWidth: '150px' }">
          <el-descriptions-item>
            <template slot="label">IP유형</template>
            <el-checkbox-group v-model="filter.ipTypes" @change="getMetadataList">
              <el-checkbox v-for="o in filterOptions.ipTypes" :key="o" :label="o" :value="o" />
            </el-checkbox-group>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">콘텐츠유형</template>
            <el-checkbox-group v-model="filter.contentTypes" @change="getMetadataList">
              <el-checkbox v-for="o in filterOptions.contentTypes" :key="o" :label="o" :value="o" />
            </el-checkbox-group>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">영상</template>
            <el-checkbox-group v-model="filter.videoTypes" @change="getMetadataList">
              <el-checkbox v-for="o in filterOptions.videoTypes" :key="o" :label="o" :value="o" />
            </el-checkbox-group>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">제작연도</template>
            <el-checkbox-group v-model="filter.years" @change="getMetadataList">
              <el-checkbox v-for="o in filterOptions.years" :key="o" :label="o" :value="o" />
            </el-checkbox-group>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">최초플랫폼</template>
            <el-checkbox-group v-model="filter.platforms" @change="getMetadataList">
              <el-checkbox v-for="o in filterOptions.platforms" :key="o" :label="o" :value="o" />
            </el-checkbox-group>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">오디오언어</template>
            <el-checkbox-group v-model="filter.audioLanguages" @change="getMetadataList">
              <el-checkbox v-for="o in filterOptions.audioLanguages" :key="o" :label="o" :value="o" />
            </el-checkbox-group>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="box table one">
      <div class="top flexB">
        <div class="tit flex">
          <h2 class="bold">메타데이터 관리 현황</h2>
          <p>
            <span class="count">{{ total }}</span
            >개의 검색결과가 있습니다.
          </p>
          <p>
            <el-button @click="showCheckedOnly()" :disabled="!isShowingCheckedOnly && checksLength === 0">
              <template v-if="isShowingCheckedOnly">
                모두 보기
              </template>
              <template v-else>
                <el-checkbox :value="true" style="pointer-events: none" />
                만 보기 ({{ checksLength }})
              </template>
            </el-button>
            <el-button @click="updateMulti()" :disabled="checksLength === 0">
              일괄 수정 ({{ checksLength }})
            </el-button>
          </p>
        </div>
        <div class="buttons">
          <el-row>
            <!-- TODO: 다운로드 아이콘 넣거나, 체크 개수 보여주거나 하기 -->
            <el-button @click="handleDownload('metadata')" icon="el-icon-download">
              표준메타
            </el-button>
            <el-button @click="handleDownload('template')" icon="el-icon-download">
              국내IPTV
            </el-button>
            <el-button @click="handleDownload('platform')" icon="el-icon-download">
              해외OTT
            </el-button>
          </el-row>
          <el-dropdown>
            <button class="registerBtn">메타데이터 등록</button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="clearfix">
                <label class="fileUpload">
                  <el-button class="blue" :loading="uploadDisabled"
                    >메타데이터 일괄 업로드</el-button
                  >
                  <input
                    type="file"
                    ref="file"
                    @change="readFile"
                    :disabled="uploadDisabled"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                </label>
              </el-dropdown-item>
              <el-dropdown-item class="clearfix">
                <router-link to="datamanage/register"
                  >메타데이터 개별 업로드</router-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div style="width: 100%; overflow: auto">
        <el-table
          class="dataTable"
          :data="list"
          @sort-change="sort"
          @selection-change="checks = $event"
          highlight-selection-row
          row-key="_id"
          resizable
          border
          style="width: 100%">
          <el-table-column
            type="selection"
            reserve-selection
            width="36">
          </el-table-column>
          <el-table-column
            label="번호"
            prop="dataNum"
            width="55">
            <template slot-scope="scope">
              <router-link :to="`datamanage/register?id=${scope.row._id}`">
                {{ scope.row.dataNum }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column width="120" label="대표이미지">
            <template slot-scope="scope">
              <div class="img" v-if="scope.row.mainImage">
                <img :src="scope.row.mainImage" alt="대표썸네일" />
              </div>
            </template>
          </el-table-column>
          <el-table-column width="140" label="시리즈제목" prop="seriesTitleKorean" class-name="double" sortable="custom">
            <template slot-scope="scope">
              <router-link :to="`datamanage/register?id=${scope.row._id}`">{{
                scope.row.seriesTitleKorean
              }}</router-link>
            </template>
          </el-table-column>
          <el-table-column width="140" label="Series Title" prop="seriesTitleEnglish" class-name="double">
            <template slot-scope="scope">
              <span>{{ scope.row.seriesTitleEnglish }}</span>
            </template>
          </el-table-column>
          <el-table-column width="120" label="콘텐츠유형" prop="contentType"></el-table-column>
          <el-table-column width="90" prop="episodeNumber" sortable="custom" align="center" header-align="left">
            <template slot="header"><span>에피소드<br />회차</span></template>
          </el-table-column>
          <el-table-column width="165" prop="episodeTitleKorean" sortable="custom">
            <template slot="header"><span>에피소드명<br />(국문)</span></template>
          </el-table-column>
          <el-table-column width="230" prop="shortSynopsis" label="단문 줄거리(160 Byte이내)" class-name="multiple">
            <template slot-scope="scope">
              <span>{{ scope.row.shortSynopsis }}</span>
            </template>
          </el-table-column>
          <el-table-column width="230" label="서버 디렉토리 경로" class-name="multiple">
            <template slot-scope="scope">
              <span>
                <button
                  v-if="scope.row.serverPath"
                  @click="copyServerPath(scope.$index)"
                  class="copy"
                >
                  <em class="material-icons"> content_copy </em>
                </button>
                {{ scope.row.serverPath }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="70" label="영상구분" prop="videoType"></el-table-column>
          <el-table-column width="90" label="상영시간" prop="duration"></el-table-column>
          <el-table-column width="80" label="관리자로그">
            <template slot-scope="scope">
              <button @click="handleLog(scope.row._id)">확인</button>
            </template>
          </el-table-column>
        </el-table>
        <NoData v-if="isNodata" />
      </div>
    </div>
    <div class="pagination" v-if="metadataList.length > 0 && !isShowingCheckedOnly">
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="30"
        @current-change="handleCurrentChange"
      ></el-pagination>

      <transition name="fade">
        <div class="dim" v-if="visible">
          <AdminLog @close="close" :dataInfo="dataInfo" />
          <div class="dimbg" @click="close"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";
import { format } from "@/mixins/format";
import AdminLog from "@/components/pop/AdminLog";
import {
  fetchMetadataList2,
  createAllMetadata,
  fetchMetadataLog,
} from "@/api/index";
import { mapState } from "vuex";
export default {
  mixins: [format],
  name: "DataManage",
  components: { AdminLog },
  data() {
    return {
      moment: moment,
      ipType: "",
      contentType: "",
      inputMode: "",
      searchWord: "",
      metadataList: [],
      dumpList: [],
      total: 0,
      currentPage: 1,
      isNodata: false,
      ip: "",
      contents: "",
      making: "",
      visible: false,
      dataInfo: {},
      dataLogList: [],
      uploadDisabled: false,
      serverPath: "",
      checks: [],
      listToShow: [],
      // FIXME: label, value
      filterOptions: {
        ipTypes: ['po', 'ta', 'ti', 'pota', 'poti', 'tati', 'zl', 'etc'],
        contentTypes: ['story', 'song_dance', 'curation', 'etc', 'sound', 'shorts', 'series'],
        videoTypes: ['2D', '3D', '2D3D', '실사', '2D실사', '3D실사', 'etc'],
        // 2017년부터 내년도까지
        years: ['etc', ...Array.from({ length: moment().year() - 2015 }, (_, i) => String(2017 + i))],
        platforms: ['iptv', 'yt', '뽀요tv', 'etc'],
        audioLanguages: [
          '한국어', '광동어(홍콩)', '독일어', '러시아어', '루마니아어', '만다린(중국,대만)', '말레이어', '몽골어', '미얀마어', '베트남어', '벵골어(인도)', '스페인어(라틴)', '스페인어(스페인)', '아랍어', '영어', '우즈베크어', '웨일스어', '이탈리아어', '인도네시아어', '일본어', '카자흐스탄어', '크메르어(캄보디아)', '키르기스어', '타갈로그(필리핀)', '타밀어(인도)', '태국어', '터키어', '페르시아어(이란)', '포르투갈어(브라질)', '포르투갈어(포르투갈)', '프랑스어', '헝가리어', '히브리어(이스라엘)', '힌디어(인도)',
        ],
      },
      sortOption: {
        prop: null,
        order: null,
      },
      filter: {
        ipTypes: [],
        contentTypes: [],
        videoTypes: [],
        years: [],
        platforms: [],
        audioLanguages: [],
      },
    };
  },
  computed: {
    ...mapState(["name", "team", "_id"]),
    list() {
      if (this.listToShow.length > 0) {
        return this.listToShow;
      } else {
        return this.metadataList;
      }
    },
    isShowingCheckedOnly() {
      return this.listToShow.length > 0;
    },
    checksLength() {
      return this.checks.length;
    },
  },
  activated() {
    this.$store.dispatch("SET_NAVBAR", "0");
    this.getMetadataList();
  },
  methods: {
    customFormatter(index) {
      return moment(index).format("YYYY년 MM월 DD일");
    },
    handleDetail(id) {
      this.$router.push({ name: "dataDetail", query: { id: id } });
    },
    reset() {
      this.ipType = "";
      this.contentType = "";
      this.inputMode = "";
      this.searchWord = "";
      this.dataList = [];
      this.total = 0;
      this.currentPage = 1;
      this.sortOption = {
        prop: null,
        order: null,
      };
      this.filter = {
        ipTypes: [],
        contentTypes: [],
        videoTypes: [],
        years: [],
        platforms: [],
        audioLanguages: [],
      };
      this.getMetadataList();
    },
    sort(event) {
      this.sortOption = event;
      this.getMetadataList();
    },
    search() {
      this.getMetadataList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getMetadataList();
    },
    handleLog(id) {
      fetchMetadataLog(id).then((res) => {
        if (res.data.status == 200) {
          this.dataInfo = res.data.data;
          this.visible = true;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleMove(id) {
      this.$router.push({ name: "dataRegister", query: { id: id } });
    },
    close() {
      this.visible = false;
    },
    showCheckedOnly() {
      if (this.listToShow.length > 0) {
        this.listToShow = [];
        return;
      }
      this.listToShow = this.checks;
    },

    getMetadataList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        ipType: this.ipType,
        contentType: this.contentType,
        filter: JSON.stringify(this.filter),
        sortBy: this.sortOption.prop,
        sortOrder: this.sortOption.order,
      };
      fetchMetadataList2(params).then((res) => {
        if (res.data.status == 200) {
          this.metadataList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },

    copyServerPath(i) {
      let tempInput = document.createElement("input");
      const text = this.metadataList[i].serverPath;
      tempInput.value = text;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      alert("서버 디렉토리 경로가 복사 되었습니다.");
    },

    updateMulti() {
      this.$router.push({
        name: "dataUpdateMulti",
        query: { ids: this.checks.map((v) => v._id) },
      });
    },

    async handleDownload(type) {
      this.downDisabled = true;
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        ipType: this.ipType,
        contentType: this.contentType,
        filter: JSON.stringify(this.filter),
        sortBy: this.sortOption.prop,
        sortOrder: this.sortOption.order,
      };
      let res = await fetchMetadataList2(params);
      if (res.data.status == 200) {
        this.dumpList = res.data.data;
      } else {
        return this.openAlert(res.data.message);
      }

      // NOTE: 체크한 항목만 다운로드할 수 있도록 필터링
      // TODO: 애초에 체크한 항목만 서버에 요청해야 한다
      if (this.checksLength > 0) {
        this.dumpList = this.checks;
      }

      let orderData = [];
      if (type == "metadata") {
        this.dumpList.forEach((item) => {
          let data = {
            폴더명: item.folderName ? item.folderName : "",
            언어: item.language ? item.language : "",
            제작유형: item.productionType ? item.productionType : "",
            IP유형: item.ipType ? item.ipType : "",
            콘텐츠유형: item.contentType ? item.contentType : "",
            대표이미지: item.mainImage ? item.mainImage : "",
            서버경로: item.serverPath ? item.serverPath : "",
            영상구분: item.videoType ? item.videoType : "",
            오디오언어: item.audioLanguages ? item.audioLanguages.join(',') : "",
            상영시간: item.duration ? item.duration : "",
            시리즈제목: item.seriesTitleKorean ? item.seriesTitleKorean : "",
            SeriesTitle: item.seriesTitleEnglish ? item.seriesTitleEnglish : "",
            시리즈시놉시스: item.seriesSynopsisKorean
              ? item.seriesSynopsisKorean
              : "",
            SeriesSynopsis: item.seriesSynopsisEnglish
              ? item.seriesSynopsisEnglish
              : "",
            에피소드회차: item.episodeNumber ? item.episodeNumber : "",
            에피소드명국문: item.episodeTitleKorean
              ? item.episodeTitleKorean
              : "",
            에피소드명영문: item.episodeTitleEnglish
              ? item.episodeTitleEnglish
              : "",
            장문줄거리: item.longSynopsis ? item.longSynopsis : "",
            단문줄거리: item.shortSynopsis ? item.shortSynopsis : "",
            작사가: item.lyricist ? item.lyricist : "",
            작곡자: item.composer ? item.composer : "",
            편곡자: item.arranger ? item.arranger : "",
            가수: item.singer ? item.singer : "",
            곡출처: item.songSource ? item.songSource : "",
            주연: item.mainCast ? item.mainCast : "",
            등장인물: item.characters ? item.characters : "",
            최초출시연도: item.initialReleaseYear
              ? item.initialReleaseYear
              : "",
            출시날짜: item.releaseDate ? item.releaseDate : "",
            장르: item.genre ? item.genre : "",
            감독: item.director ? item.director : "",
            작가: item.writer ? item.writer : "",
            성우: item.voiceActor ? item.voiceActor : "",
            제작사: item.productionCompany ? item.productionCompany : "",
            화질: item.videoQuality ? item.videoQuality : "",
            화면비율: item.aspectRatio ? item.aspectRatio : "",
          };
          orderData.push(data);
        });
        var excelData = XLSX.utils.json_to_sheet(orderData); // table id를 넣어주면된다
        var workBook = XLSX.utils.book_new(); // 새 시트 생성
        XLSX.utils.book_append_sheet(workBook, excelData, "표준 메타데이터"); // 시트 명명, 데이터 지정
        XLSX.writeFile(
          workBook,
          `표준 메타데이터${moment().format("YYYYMMDDHHmm")}.xlsx`
        ); // 엑셀파일 만듬
      } else if (type == "template") {
        this.dumpList.forEach((item) => {
          let data = {
            "시리즈명/프로그램": item.seriesTitleKorean
              ? item.seriesTitleKorean
              : "",
            "시리즈명(앨범명/영문)": item.seriesTitleEnglish
              ? item.seriesTitleEnglish
              : "",
            시리즈시놉시스: item.seriesSynopsisKorean
              ? item.seriesSynopsisKorean
              : "",
            에피소드회차: item.episodeNumber ? item.episodeNumber : "",
            콘텐츠명: item.episodeTitleKorean ? item.episodeTitleKorean : "",
            "콘텐츠명 (영문)": item.episodeTitleEnglish
              ? item.episodeTitleEnglish
              : "",
            장문줄거리: item.longSynopsis ? item.longSynopsis : "",
            단문줄거리: item.shortSynopsis ? item.shortSynopsis : "",
            대표장르: item.genre ? item.genre : "",
            작사: item.lyricist ? item.lyricist : "",
            작곡: item.composer ? item.composer : "",
            편곡: item.arranger ? item.arranger : "",
            가수: item.singer ? item.singer : "",
            곡출처: item.songSource ? item.songSource : "",
            "배급사(CP)": item.productionCompany ? item.productionCompany : "",
            감독: item.director ? item.director : "",
            작가: item.writer ? item.writer : "",
            "주연/출연": item.mainCast ? item.mainCast : "",
            등장인물: item.characters ? item.characters : "",
            "목소리(더빙)성우": item.voiceActor ? item.voiceActor : "",
            "상영시간(예: 01:00:00)": item.duration ? item.duration : "",
            "화질(UHD/HD/SD)": item.videoQuality ? item.videoQuality : "",
            "영상(2D/3D)": item.videoType ? item.videoType : "",
            화면비율: item.aspectRatio ? item.aspectRatio : "",
            오디어언어: item.audioLanguage ? item.audioLanguage : "",
            "더빙여부(Y/N)": item.dubbingStatus ? item.dubbingStatus : "",
            더빙언어: item.dubbedLanguage.length > 0 ? item.dubbedLanguage : "",
            "자막여부(Y/N)": item.subtitleStatus ? item.subtitleStatus : "",
            자막언어:
              item.subtitleLanguage.length > 0 ? item.subtitleLanguage : "",
          };
          orderData.push(data);
        });
        var excelData2 = XLSX.utils.json_to_sheet(orderData); // table id를 넣어주면된다
        var workBook2 = XLSX.utils.book_new(); // 새 시트 생성
        XLSX.utils.book_append_sheet(workBook2, excelData2, "국내 IPTV 템플릿"); // 시트 명명, 데이터 지정
        XLSX.writeFile(
          workBook2,
          `국내 IPTV 템플릿${moment().format("YYYYMMDDHHmm")}.xlsx`
        ); // 엑셀파일 만듬
      } else {
        this.dumpList.forEach((item) => {
          let data = {
            LanguageOfMetadata: item.language ? item.language : "",
            콘텐츠유형: item.contentType ? item.contentType : "",
            시리즈제목: item.seriesTitleKorean ? item.seriesTitleKorean : "",
            SeriesTitle: item.seriesTitleEnglish ? item.seriesTitleEnglish : "",
            EpisodeSequenceNumber: item.episodeNumber ? item.episodeNumber : "",
            시리즈시놉시스: item.seriesSynopsisKorean
              ? item.seriesSynopsisKorean
              : "",
            SeriesSynopsis: item.seriesSynopsisEnglish
              ? item.seriesSynopsisEnglish
              : "",
            "FirstReleaseYear(최초출시연도)": item.initialReleaseYear
              ? item.initialReleaseYear
              : "",
            "Genre(장르)": item.genre ? item.genre : "",
            "Director(감독)": item.director ? item.director : "",
            "Creator(작가)": item.writer ? item.writer : "",
            "Actors(성우)": item.voiceActor ? item.voiceActor : "",
          };
          orderData.push(data);
        });
        var excelData3 = XLSX.utils.json_to_sheet(orderData); // table id를 넣어주면된다
        var workBook3 = XLSX.utils.book_new(); // 새 시트 생성
        XLSX.utils.book_append_sheet(workBook3, excelData3, "해외 OTT 플랫폼"); // 시트 명명, 데이터 지정
        XLSX.writeFile(
          workBook3,
          `해외 OTT 플랫폼${moment().format("YYYYMMDDHHmm")}.xlsx`
        ); // 엑셀파일 만듬
      }

      this.downDisabled = false;
    },

    readFile(event) {
      let reader = new FileReader();
      reader.onload = () => {
        let data = reader.result;

        let workBook = XLSX.read(data, { type: "binary" });
        let sheetName = workBook.SheetNames[0];
        let sheet = workBook.Sheets[sheetName];
        this.rows = XLSX.utils.sheet_to_json(sheet);
        if (this.rows.length == 0) {
          return alert("파일을 재확인 후 다시 업로드해주세요.");
        }

        // if (
        //   keys[0] != "주문번호" ||
        //   keys[1] != "수량" ||
        //   keys[2] != "택배사" ||
        //   keys[3] != "운송장번호"
        // ) {
        //   return alert("파일을 재확인 후 다시 업로드해주세요.");
        // }
        let dump = this.rows.map((item) => {
          let data = {
            folderName: item["폴더명"],
            language: item["언어"],
            productionType: item["제작유형"],
            ipType: item["IP 유형"],
            contentType: item["콘텐츠유형"],
            mainImage: item["대표이미지"],
            serverPath: item["서버 디렉토리 경로"],
            videoType: item["영상구분"],
            audioLanguage: item["오디오 언어"],
            dubbingStatus: item["더빙여부"] ? item["더빙여부"] : "N",
            dubbedLanguage: item["더빙언어"],
            subtitleStatus: item["자막여부"] ? item["자막여부"] : "N",
            subtitleLanguage: item["자막언어"],
            duration: item["상영시간"],
            seriesTitleKorean: item["시리즈제목"],
            seriesTitleEnglish: item["SeriesTitle"],
            seriesSynopsisKorean: item["시리즈시놉시스"],
            seriesSynopsisEnglish: item["SeriesSynopsis"],
            episodeNumber: item["에피소드회차"],
            episodeTitleKorean: item["에피소드명(국문)"],
            episodeTitleEnglish: item["에피소드명(영문)"],
            longSynopsis: item["장문 줄거리(1,800 byte)"],
            shortSynopsis: item["단문 줄거리(160 Byte이내)"],
            lyricist: item["작사가"] ? item["작사가"] : "(주)아이코닉스",
            composer: item["작곡자"] ? item["작곡자"] : "(주)아이코닉스",
            arranger: item["편곡자"] ? item["편곡자"] : "(주)아이코닉스",
            singer: item["가수"] ? item["가수"] : "(주)아이코닉스",
            songSource: item["곡 출처"] ? item["곡 출처"] : "(주)아이코닉스",
            mainCast: item["주연"],
            characters: item["등장인물"],
            initialReleaseYear: item["최초출시연도"],
            releaseDate: item["출시날짜"],
            genre: item["장르"],
            director: item["감독"],
            writer: item["작가"],
            voiceActor: item["성우"],
            productionCompany: item["제작사"],
            videoQuality: item["화질(only HD)"],
            aspectRatio: item["화면비율"],
          };
          return data;
        });
        this.uploadTrackingNumber(dump);
      };
      reader.readAsBinaryString(event.target.files[0]);
      this.$refs.file.value = null;
    },
    uploadTrackingNumber(dump) {
      this.uploadDisabled = true;
      createAllMetadata(dump).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "메타데이터 일괄 등록이 완료 되었습니다.",
            type: "success",
          });
          this.uploadDisabled = false;
          this.getMetadataList();
        } else {
          this.$refs.file.value = null;
          this.uploadDisabled = false;
          let message = "파일 업로드중 오류가 발생 하였습니다.";
          return alert(message);
        }
      });
    },
  },
};
</script>
